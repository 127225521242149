*{
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.corpPag{
    margin-left: 100px;
    margin-right: 100px;

}


a{
    display: inline-block;
    color:white;
    font-weight: bold;
    text-decoration: none;
    margin: 0px 4px;
}

/* Cabecalho */
.cabPai{
    background: rgb(0,38,107);
    background: linear-gradient(128deg, rgba(0,38,107,1) 0%, rgba(68,114,196,1) 40%);  
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 8px;
    padding-bottom: 30px;
    width: 100%;
    height: 60px

}

.menuPai{
    display: flex;
    justify-content: left;
    flex-direction: row;
    width: 100%;
    padding-top: 0px;
}

.linkMenu{
    text-align: center;
    display: inline-block;
    color:white;
    margin:4px;
    font-weight: bold;
    font-size: 20px;
    background: none;
	border: none;
	padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
	cursor: pointer;
	outline: inherit;

}
.divLogo2 {

    height: 170px;
    width: 170px;
    border-radius: 50%;
    margin-left:300px;
    margin-right: 300px;
}
.divLogo1 {
    height: 85px;
    width: 85px;
    border-radius: 50%;    
}

/* BODY DA PAGINA */
.bodyhome{
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 10px;
    flex-wrap: wrap;
    border-radius: 10px;
    -webkit-box-shadow: 1px 1px 44px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 44px -3px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 44px -3px rgba(0,0,0,0.75);
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left:40px;
    margin-right:40px ;
  
}


.PontoColPrinc{
    display: flex ;
    justify-content: center;
    flex-direction: row;
}

.feeds{
    box-shadow: 9px 8px 20px rgba(0, 0, 0, 0.416);
    display: flex;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap ;
    display: flex;
    padding: 10px;
    margin: 20px;
    border: 1px solid;
    border-radius: 10px;
    background-color: rgb(255, 255, 255, 0.875);
    width: 500px;
    height: 480px;
}



/*  css do Pontos coleta  */

.box-container{
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap ;
    border: 0px;
    border-radius: 5px;
}

.nome-empresa-nmst{
    width: 200px;
    padding: 10px;
    margin-bottom: 200px;
    margin-left: 20px;
    border:solid 2px;
    border-radius: 5px;
    color:white;

}

.data-i{
    width: 100px;
    padding: 10px;
    margin-top: 40px;
    margin-left: 20px;
    border: solid 2px;
    border-radius: 5px;
    color:white;
}

.data-t{
    width: 100px;
    padding: 10px;
    margin-top: 20px;
    margin-left: 20px;
    border: solid 2px;
    border-radius: 5px;
    color:white;
}

.button-home{
    margin-left: 400px;

}

.buttom{
    background-color: rgba(6,47,83);
    margin: 1px;
    padding: 5px;
    border:solid 1px black ;
    border-radius: 5px;
    color:#ffffff;
}


/* MOSTRANDO INFO */

.Img{
    
    width: 500px;
    height: 150px;
    border:solid 2px;
    border-radius: 5px;
    
}

.td,.tr{
    text-align: center;
    margin-top: 10px;
    width: 300px;
    padding: 2px;
    border-radius: 2px;
    border-bottom: 2px solid;
    border-color: black;

}

td,tr{
    text-align: start;
    margin-top: 10px;
    width: 300px;
    padding: 2px;
    border-radius: 2px;
    border-bottom: 2px solid;
    border-color: black;

}

.desc{
    text-align: start;
    overflow:scroll solid 2px; 
    width:500px; 
    height:70px;
    
}

.trent{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 60px;
    background-color: rgba(145, 145, 145, 0.31);
    border-radius: 10px;
    width: 400px;
    
}

/* DETALHAMENTO */
.teste{
    border-radius: 10px;
    box-shadow: 9px 8px 20px rgba(0, 0, 0, 0.416);
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    border: 3px solid;
    border-color:  rgb(16, 64, 103);
    margin:100px;
    margin-left:auto;
    margin-right:auto ;
    padding:10px;
    width:1000px;
    height:500px;
    box-sizing: border-box;
}

/* CSS LOGIN*/
.Adm{
    height:25px;
    width:200px;
    background-color: rgba(234, 234, 234);
    align-items: center;
    align-self: center;
    text-align: center;
    border-color:  rgb(89, 180, 255);
    margin:0px;
    margin-top:40px;
    padding: 10px;
    border-radius: 10px;
}

.Adm2{
    height:25px;
    width:200px;
    background-color: rgb(234, 234, 234);
    align-items: center;
    align-self: center;
    text-align: center;
    border-color:  rgb(89, 180, 255);
    margin:0px;
    margin-top:20px;
    padding: 10px;
    border-radius: 10px;
}
.divLogo {
    height: 170px;
    width: 170px;
    margin: auto;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.875);
}

.box-conteiner-log{
    border-radius: 10px;
    box-shadow: 9px 8px 20px rgba(0, 0, 0, 0.416);
    background-color: rgba(255, 255, 255, 0.875);
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    border: 3px solid;
    border-color:  rgb(16, 64, 103);
    margin:100px;
    margin-left:auto;
    margin-right:auto;
    padding:10px;
    width:400px;
    height:570px;
    box-sizing: border-box;
    
}

.paddinglog{
    padding: 40px;
}

.background-Log{ 
    background-color: rgba(145, 145, 145, 0.31);
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
}

.buttonEnter{
    height:30px;
    width:80px;
    background-color: rgb(16, 64, 103);
    color: white;
    font-weight: bold;
    border-color:  rgb(4, 35, 59);
    margin:0px;
    margin-top:30px;
    padding: 10px;
    border-radius: 10px;
    width: 33%;
    height: 100%;
}

/* FORM COLETA */

.telaForm{
    background-color: rgba(145, 145, 145, 0.31);
    height: 100%;
    width: 100%;
    padding-bottom: 30px;
    
}
.cssForm{
    position: relative;
    height:15px;
    width: 200px;
    background-color: rgb(234, 234, 234);
    border-color:  rgb(91, 179, 252);
    margin-top: 15px;
    margin-left: 5px;
    margin-right:60px;
    padding: 15px;
    border-radius: 10px;
    
}
.cssForm1{
    position: relative;
    height:200px;
    width: 700px;
    background-color: rgb(234, 234, 234);
    border-color: rgb(89, 180, 255);
    border-radius: 10px;
    margin-top: 20px;
    margin-right:60px;
    padding: 15px;

}

.box-conteiner-form{
    display: flex;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap ;
    border-radius: 10px;
    box-shadow: 9px 8px 20px rgba(0, 0, 0, 0.416);
    background-color: rgba(255, 255, 255, 0.875);
    border: 3px solid;
    border-color:  rgb(16, 64, 103);
    margin:100px;
    margin-left:auto;
    margin-right:auto ;
    padding-top: 50px;
    width:800px;
    height: 1350px;
    box-sizing: border-box;
}
.datainicioForm{
    position: relative;
    border: 2px solid;
    height:20px;
    width: 200px;
    background-color: rgb(212, 212, 212);
    border-color:  rgb(89, 180, 255);
    border-radius: 10px;
    margin-top: 20px;
    margin-right:60px;
    padding: 15px;
}

.dataterminoForm{
    position: relative;
    border: 2px solid;
    height:20px;
    width: 200px;
    background-color: rgb(212, 212, 212);
    border-color:  rgb(89, 180, 255);
    border-radius: 10px;
    margin-top: 20px;
    margin-right:60px;
    padding: 15px;
}

.buttonEnter1{
    align-content: flex-end;
    height:40px;
    width:80px;
    background-color: rgb(16, 64, 103);
    color: white;
    font-weight: bold;
    border-color:  hsl(206, 87%, 12%);
    margin-left:349px;
    margin-top:30px;
    padding: 10px;
    border-radius: 10px;
    
}

.box-input{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap ;
    margin-right:auto;
    margin-left:auto;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 0px;
    width:800px;
    height: 900px;
    box-sizing: border-box;

}

.top{

    margin-bottom: 20px;
    margin-right:auto;
    margin-left:auto;

}

.Autorização{
    border-radius: 10px;
    box-shadow: 9px 8px 20px rgba(0, 0, 0, 0.416);
    background-color: rgb(255, 255, 255, 0.875);
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    border: 3px solid;
    border-color:  rgb(16, 64, 103);
    margin:100px;
    margin-left:auto;
    margin-right:auto ;
    padding:10px;
    width:400px;
    height:500px;
    box-sizing: border-box;
}

.message_neg{
    width:200px;
    box-shadow: 9px 8px 20px rgba(0, 0, 0, 0.416);
    background-color: rgb(234, 234, 234);
    align-items: center;
    align-self: center;
    text-align: center;
    border: 2px solid;
    border-color:  rgb(16, 64, 103);
    margin:0px;
    margin-top:20px;
    padding: 10px;
    border-radius: 10px;
}

.informacoesColeta{
    border-radius: 10px;
    box-shadow: 9px 8px 20px rgba(0, 0, 0, 0.416);
    background-color: rgba(255, 255, 255, 0.875);
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    border: 3px solid;
    border-color:  rgb(16, 64, 103);
    margin-left:auto;
    margin-right:auto;
    margin-top: 40px;
    margin-bottom: 60px;
    padding:5px;
    width:950px;
    height:550px;
    box-sizing: border-box;
}
#fontP{
    font-size: 18px;
}

.button_neg{
    height:30px;
    width:80px;
    background-color: rgb(16, 64, 103);
    color: white;
    font-weight: bold;
    border-color:  rgb(4, 35, 59);
    margin:0px;
    margin-top:30px;
    padding: 10px;
    border-radius: 10px;
    width: 33%;
    height: 100%;

}

.telaAutorizacao{
    background-color: rgba(145, 145, 145, 0.31);
}

.Senac.jpg{
    background-image:-moz-linear-gradient('Senac.jpg');
}


/* CSS SOBRE*/

.sobre{
    border-radius: 10px;
    box-shadow: 9px 8px 20px rgba(0, 0, 0, 0.416);
    background-color: rgba(255, 255, 255, 0.875);
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    border: 3px solid;
    border-color:  rgb(16, 64, 103);
    margin-top: 40px;
    margin-left:auto;
    margin-right:auto ;
    padding:10px;
    width:950px;
    height:360px;
    box-sizing: border-box;
    
}

.paddingsobre{
    padding: 40px;
}

.divSobrepai{
    background-color: rgba(145, 145, 145, 0.31);
    display: flex;
    justify-content: center;
    width: 100%;
    height:66.8vh;    
}

h2{
    padding-top: 6vmin;
    justify-content: center;
    align-items: center;
}

.background-sobre{ 
    background-color: rgba(145, 145, 145, 0.31);
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
}